body {
  background: $black;
  overflow-x: hidden;
  margin: 0;
  position: relative;
  padding: 0;
  height: 100%;
}

select,
input,
button {
  outline: none;
  border: none;
  font-family: $font-family-sans-serif;
  background: none;
}
