.Confirmation-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 9rem;
  height: 100%;
  position: relative;
  min-height: calc(100vh - 9rem);

  @include lg {
    min-height: calc(100vh - 5rem);
    padding-top: 5rem;
    padding-left: 5.2rem;
    padding-right: 5.2rem;
  }
}

.Confirmation-menu {
  display: flex;
  align-items: center;
  height: 3rem;

  @include lg {
    height: 5.4rem;
  }
}

.Confirmation-workcoLogo {
  height: 100%;
}

.Confirmation-backslash {
  line-height: 4rem;
  font-size: 4rem;
  margin-left: 2rem;
  margin-right: 1.5rem;

  @include lg {
    line-height: 7rem;
    font-size: 7rem;
    margin-left: 4.5rem;
    margin-right: 3rem;
  }
}

.Confirmation-sxswLogo {
  height: 2rem;

  @include lg {
    height: 3.5rem;
  }
}

.Confirmation-title {
  @include large-text;
  margin-top: 4.4rem;
  margin-bottom: 3.8rem;

  @include lg {
    margin-top: 10.8rem;
    margin-bottom: 3rem;
  }
}

.Confirmation-theme {
  margin-bottom: 3rem;
}

.Confirmation-location {
  margin-top: 1.5rem;
  margin-bottom: 5rem;

  @include lg {
    margin-top: 3rem;
    margin-bottom: 4rem;
  }
}

.Confirmation-flower {
  margin-left: -3rem;

  @include lg {
    display: none;
  }
}

.Confirmation-calendarPosition {
  margin-bottom: 3rem;

  @include lg {
    margin-bottom: 3rem;
  }

  .addeventatc_dropdown {
    font-family: $font-family-sans-serif;
    border-radius: 0;

    span[role='menuitem'] {
      em {
        display: none;
      }
    }

    .copyx,
    .ateyahoo,
    .ateoutlookcom
     {
      display: none;
    }
  }
}

.Confirmation-button.addeventatc {
  display: inline;
  font-weight: 400;
  font-size: 1.8rem;
  font-family: $font-family-sans-serif;
  background: none;
  color: $white !important;
  border: none !important;
  border-radius: 0;
  outline: none;
  box-shadow: none !important;
  border-bottom: thin solid $turquoise !important;
  padding: 0;

  .addeventatc_icon {
    display: none;
  }

  &:hover {
    font-size: 1.8rem;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: $white !important;
    background: none;
  }

  @include lg {
    font-size: 3rem;

    &:hover {
      font-size: 3rem;
    }
  }

  .Confirmation-hidden {
    display: none;
  }
}
