$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;

// ------ Colors
$time: 500ms;

// ------ Colors
$black: #000;
$white: #fff;
$turquoise: #87DBE1;

// ------ Breakpoints
$breakpoint-lg: 1200px;
$breakpoint-md: 600px;
