.Rsvp-page {
  padding-left: 3rem;
  padding-right: 3rem;

  @include md {
    padding-left: 2.2rem;
    padding-left: 2.2rem;
  }

  @include lg {
    padding-left: 5.2rem;
    padding-right: 5.2rem;
  }
}

.Rsvp-menu {
  display: flex;
  align-items: center;
  height: 3rem;
  margin-top: 9rem;

  @include lg {
    margin-top: 5rem;
    height: 5.4rem;
  }
}

.Rsvp-img-wrapper {
  margin-top: 3rem;
  position: relative;
  padding-bottom: 75%;
}

.Rsvp-img {
  position: absolute;
  object-position: center;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Rsvp-link {
  @include large-text;
  position: fixed;
  right: 3rem;
  bottom: 3rem;
  z-index: 1;
  border-bottom: thin solid $turquoise;
  line-height: 3.5rem !important;
  padding: 0;
  background: none;
  color: $white;

  @include md {
    bottom: 5.2rem;
  }

  @include lg {
    line-height: 10rem !important;
    right: 5.2rem;
    bottom: 5.2rem;
  }
}

.Rsvp-workcoLogo {
  height: 3rem;

  @include lg {
    height: 5.4rem;
  }
}

.Rsvp-backslash {
  line-height: 4rem;
  font-size: 4rem;
  margin-left: 2rem;
  margin-right: 1.5rem;

  @include lg {
    line-height: 7rem;
    font-size: 7rem;
    margin-left: 4.5rem;
    margin-right: 3rem;
  }
}

.Rsvp-sxswLogo {
  height: 2rem;

  @include lg {
    height: 3.5rem;
  }
}

.Rsvp-title {
  @include large-text;
  margin-top: 4.4rem;
  margin-bottom: 3.8rem;

  @include lg {
    margin-top: 10.8rem;
    margin-bottom: 7.3rem;
  }
}

.Rsvp-location {
  margin-top: 1.5rem;
  margin-bottom: 12.3rem;

  @include md {
    margin-bottom: 19.2rem;
  }

  @include lg {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
}

.Rsvp-flower {
  position: relative;
  left: -32rem;

  img {
    height: 33.4rem;
  }

  @include md {
    left: -39rem;

    img {
      height: 44.1rem;
    }
  }

  @include lg {
    left: -5.2rem;

    img {
      height: 34.8rem;
    }
  }
}

.Rsvp-flower--ground {
  border-bottom: thin solid $turquoise;
  width: 33rem;
  margin-top: -0.6rem;
  position: absolute;
  transform-origin: left;

  @include md {
    width: 45rem;
  }

  @include lg {
    transform: scaleX(0);
    margin-top: -1rem;
    width: 50rem;
  }
}

.Rsvp-description {
  margin-top: 7.5rem;
  margin-bottom: 5.5rem;
  width: 82%;

  @include md {
    margin-top: 18.2rem;
    width: 57%;
  }

  @include lg {
    margin-top: 4rem;
    margin-bottom: 5rem;
    width: 53%;
  }
}

.Rsvp-description--text {
  margin: 2.5rem 0;

  @include lg {
    margin: 4rem 0;
  }
}

.Rsvp-rock {
  margin-right: -3rem;
  display: flex;
  justify-content: flex-end;

  @include lg {
    position: relative;
    width: 100%;
    right: -6rem;
    align-items: baseline;
  }
}

.Rsvp-rock--svg {
  display: none;
  margin-left: 4rem;
  transition: opacity $time;

  @include lg {
    display: block;
    opacity: 0;
  }
}

.Rsvp-rock-small {
  @include lg {
    display: none;
  }
}

.Rsvp-rock--show {
  opacity: 1;
}

.Rsvp-rock--ground {
  display: none;

  @include lg {
    display: block;
    right: 0.1rem;
    bottom: 0.1rem;
    position: relative;
    transform-origin: right;
    border-bottom: thin solid $turquoise;
    transform: scaleX(0);
    width: 34.1rem;
  }
}

.Rsvp-invitation {
  @include large-text;
  position: relative;
  margin-top: 9rem;
  margin-bottom: 5rem;
  opacity: 1;
  transition: color $time, border-color $time;

  @include md {
    margin-bottom: 5rem;
  }

  @include lg {
    display: flex;
    margin-top: 17.3rem;
  }
}

.Rsvp-invitation--text {
  flex-shrink: 0;
  * {
    transition: opacity $time, border-color $time;
  }

  @include md {
    width: 54%;
  }

  @include lg {
    width: 60%;
  }
}

.Rsvp-dimText {
  .Rsvp-invitation--text * {
    opacity: 0.2;
    border-color: rgba($white, 0.07) !important;
  }
}

.Rsvp-company {
  cursor: default;
  border-bottom: thin solid $turquoise;
}

.Rsvp-company--description {
  margin-top: 4rem;
  width: 70%;
  position: relative;

  @include lg {
    width: 100%;
    margin-top: 0;
    margin-left: 1rem;
  }
}

.Rsvp-company--text {
  @include body-copy;
  top: 0;
  opacity: 0;
  transition: opacity $time;
  position: absolute;
  pointer-events: none;

  @include md {
    width: 85%;
  }

  @include lg {
    width: 100%;
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

.Rsvp-company--airbnbText {
  position: relative;
}

$companyList: "airbnb", "google", "digitalundivided";

@each $item in $companyList {
  .Rsvp-invitation--#{$item}Button {
    .Rsvp-company--#{$item} {
      opacity: 1;
      border-color: $turquoise !important;
    }
  }

  .Rsvp-invitation--#{$item}Decription {
    .Rsvp-company--#{$item}Text {
      opacity: 1;
      color: $white;
      pointer-events: all;
    }
  }
}

.Rsvp-stars {
  height: 15rem;
  margin-left: -10rem;

  @include lg {
    display: none;
  }
}

.Rsvp-host {
  margin-top: 9rem;
  margin-bottom: 4rem;

  @include md {
    width: 60%;
  }

  @include lg {
    width: 52%;
  }
}

.Rsvp-host--title {
  @include large-text;
}

.Rsvp-host--description {
  margin-top: 4rem;
  margin-bottom: 2.5rem;

  @include lg {
    margin-top: 7rem;
    margin-bottom: 4rem;
  }
}

.Rsvp-host--link {
  text-decoration: none;
  color: inherit;
  border-bottom: thin solid $turquoise;
}

.Rsvp-stars--largeScreen {
  display: none;
  pointer-events: none;
  width: 100%;
  justify-content: flex-end;
  position: relative;
  top: -11rem;

  @include lg {
    display: flex;

    .Rsvp-stars {
      display: block;
      margin-left: 0;
      height: 24rem;
    }
  }
}

.Rsvp-form {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: 10rem;
  margin-top: 8.4rem;
  margin-left: auto;
  width: 25rem;

  @include md {
    margin-top: 16rem;
    margin-bottom: 14rem;
  }

  @include lg {
    width: 75rem;
    margin-top: 6rem;
  }
}

.Rsvp-form--title {
  @include large-text;
  margin-bottom: 3.5rem;
}

.Rsvp-inputWrapper {
  @include large-text;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
  width: 25rem;
  height: 4rem;

  @include lg {
    width: 75rem;
    height: 12rem;
  }
}

.Rsvp-input {
  padding: 0;
  height: 4rem;
  margin: 0;
  font: inherit;
  background-color: transparent;
  border: none;
  text-align: right;
  color: $white;
  line-height: 4rem;

  &::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  &::-webkit-input-placeholder {
    color: $white;
    opacity: 0.2;
  }
  &::-moz-placeholder {
    color: $white;
    opacity: 0.2;
  }
  &:-ms-input-placeholder {
    color: $white;
    opacity: 0.2;
  }
  &:-moz-placeholder {
    color: $white;
    opacity: 0.2;
  }

  width: 25rem;

  @include lg {
    height: 12rem;
    width: 75rem;
    line-height: 12rem;
  }
}

.Rsvp-input--error {
  color: rgba($white, 0.2);
}

.Rsvp-select--label {
  position: absolute;
  flex-shrink: 0;
  width: 25rem;
  right: 4rem;
  top: 0;
  text-align: right;
  pointer-events: none;
  color: rgba($white, 0.2);

  @include lg {
    right: 6rem;
    width: 72rem;
  }
}

.Rsvp-select--labelBlack {
  color: $white;
}

.Rsvp-select {
  font: inherit;
  color: rgba($black, 0);
  width: 25rem;
  height: 4rem;
  border: none;
  background: url(../assets/chevron.svg) no-repeat right $black;
  -webkit-appearance: none;
  background-position-x: 23rem;

  option {
    color: $white;
  }

  &::-ms-expand {
    display: none;
  }

  @include lg {
    font-size: 5rem;
    background: url(../assets/chevron-thic.svg) no-repeat right $black;
    color: transparent;
    width: 70rem;
    background-position-x: 66rem;
    height: 12rem;
  }

  & + .Rsvp-input--errorMessage {
    right: 4rem;

    @include lg {
      right: 6rem;
    }
  }
}

.Rsvp-error {
  @include large-text;
  position: absolute;
  align-self: center;
  right: 0;
  display: none;
  top: 0;
}

.Rsvp-error--noRsvp {
  right: 4rem;

  @include lg {
    right: 6rem;
  }
}

.Rsvp-error--show {
  pointer-events: none;
  display: block;
  color: $turquoise;
}

.Rsvp-form--submit {
  @include large-text;
  color: $white;
  background: $black;
  border: none;
  border-bottom: solid thin $turquoise;
  padding: 0;

  &:disabled {
    color: $white;
    border-bottom: solid thin $turquoise;
  }
}

.Rsvp-hidden-input {
  opacity: 0;
  pointer-events: none;
}
